export default {
  typeText(type, showGroup = false) {
    switch (type) {
      case 'group':
        return showGroup ? 'Group' : 'Service';
      case 'service':
        return 'Service';
      case 'adnetwork':
        return 'Ad Network';
      case 'mediahouse':
        return 'Media house';
      case 'ranking_channel':
        return 'Channel';
      default:
        return type;
    }
  },
  compareBeforeServiceCategories: [],
  compareDataBefore: [],
  compareAfterServiceCategories: [],
  compareDataAfter: [],
  compareTrafficData: [],
  compareAudienceAttributes: {},
  compareAudienceAttributesData: []
};
