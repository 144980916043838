import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers';
import { customerAdministrationRoutes } from './customerAdministration';
import { ratingsAdministrationRoutes } from './ratingsAdministration';
import { generalAdministrationRoutes } from './generalAdministration';
import { contractAdministrationRoutes } from './contractAdministration';
import { billingRoutes } from './billingAdministration';
import { i18nRoutes } from './internationalization';
import { oewaAdministrationRoutes } from './oewaAdministration';
import { campaignControlAdministration } from './campaignControlAdministration';
import { brandCheckAdministration } from './brandCheckAdministration';

export const adminModuleRoutes = exportRoutesWithAppMetaField(APPS.MANAGEMENT, [
  {
    path: '/:country_code/profile',
    name: 'user.profile',
    component: () => import(/* webpackChunkName: "admin" */ '../../../views/pages/management/Profile.vue'),
    meta: { auth: true, hasCountrySwitcher: true }
  },
  {
    path: '/:country_code/admin',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: '',
        name: 'admin.home',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../../../views/pages/management/AdminHome.vue'),
        meta: { auth: true, hasCountrySwitcher: true, module: 'admin' }
      },
      ...customerAdministrationRoutes,
      ...contractAdministrationRoutes,
      ...ratingsAdministrationRoutes,
      ...generalAdministrationRoutes,
      ...i18nRoutes,
      ...billingRoutes,
      ...oewaAdministrationRoutes,
      ...campaignControlAdministration,
      ...brandCheckAdministration
    ]
  }
]);
