import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { API_METHODS } from 'composables/useAxios';
import { useRoute } from 'vue-router';

export const useBrandCheck = () => {
  const route = useRoute();

  const {
    state: { loading: fetchingFlightCreateOrEditData, data: flightCreateOrEditData },
    fetcher: campaignFlightFetcher
  } = useAxios();

  const {
    state: { loading: savingFlight },
    fetcher: saveFlightFetcher
  } = useAxios();

  const {
    state: {
      loading: loadingBrandCheckCampaignsCustomers,
      error: errorBrandCheckCampaignsCustomers,
      data: brandCheckCampaignsCustomers
    },
    fetcher: fetchBrandCheckCampaignsCustomersFetcher
  } = useAxios();

  const {
    state: { loading: loadingBrandCheckFlights, error: errorBrandCheckFlights, data: brandCheckFlights },
    fetcher: fetchBrandCheckFlightsFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingCampaignAndFlightData,
      error: fetchCampaignAndFlightDataError,
      data: campaignAndFlightData
    },
    fetcher: campaignAndFlightDataFetcher
  } = useAxios();

  const fetchFlightCreateOrEditData = async ({ campaignId, flightId = null }) => {
    const query = flightId ? { flight_id: flightId } : {};
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.create-or-edit.data',
      params: {
        id: campaignId
      },
      query
    });

    return campaignFlightFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const saveFlight = async ({ campaignId, formData, flightId = null }) => {
    const apiRoute = flightId
      ? getApiRoute({
          name: 'api.campaigns.flights.update',
          params: {
            id: campaignId,
            flight_id: flightId
          }
        })
      : getApiRoute({
          name: 'api.campaigns.flights.create',
          params: {
            id: campaignId
          }
        });

    return saveFlightFetcher({
      url: apiRoute,
      method: API_METHODS.POST,
      requestBody: formData
    });
  };

  const fetchBrandCheckCampaignsCustomers = async () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.list.brandcheck',
      query: {
        ...route.query
      }
    });

    return fetchBrandCheckCampaignsCustomersFetcher({
      url: apiRoute,
      responseAdapter: ({ customers }) => customers
    });
  };

  const fetchBrandCheckFlights = async ({ campaignId }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights',
      params: {
        id: campaignId
      },
      query: {
        ...route.query
      }
    });

    return fetchBrandCheckFlightsFetcher({
      url: apiRoute,
      responseAdapter: ({ flights }) => flights
    });
  };

  const fetchCampaignAndFlightData = ({ campaignId, flightId }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.data',
      params: {
        id: campaignId,
        flight_id: flightId
      },
      query: {
        ...route.query
      }
    });

    return campaignAndFlightDataFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  return {
    fetchFlightCreateOrEditData,
    fetchingFlightCreateOrEditData,
    flightCreateOrEditData,
    saveFlight,
    savingFlight,
    fetchBrandCheckCampaignsCustomers,
    loadingBrandCheckCampaignsCustomers,
    errorBrandCheckCampaignsCustomers,
    brandCheckCampaignsCustomers,
    fetchBrandCheckFlights,
    loadingBrandCheckFlights,
    errorBrandCheckFlights,
    brandCheckFlights,
    fetchCampaignAndFlightData,
    fetchingCampaignAndFlightData,
    fetchCampaignAndFlightDataError,
    campaignAndFlightData
  };
};
