export const brandCheckAdministration = [
  {
    path: 'brand-check',
    component: {
      render: () => <RouterView />
    },
    children: [
      {
        path: 'manage-flights',
        name: 'admin.brand-check.flights',
        component: () =>
          import(
            /* webpackChunkName: "admin" */ '../../../views/pages/management/brandcheck/BrandCheckFlightsManagement.vue'
          ),
        meta: {
          auth: true,
          hasCountrySwitcher: true,
          module: 'brand_check_admin'
        }
      }
    ]
  }
];
