import { useAxios } from 'composables';
import { getApiRoute } from 'routeconfig';
import { useRoute } from 'vue-router';

export const useBrandCheckSurvey = () => {
  const route = useRoute();

  const campaignId = route.params.id;
  const flightId = route.params.flight_id;

  const {
    state: {
      loading: fetchingSurveyPreviewLinks,
      error: fetchingSurveyPreviewLinksError,
      data: surveyPreviewLinks
    },
    fetcher: surveyPreviewLinksFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: {
      loading: fetchingSurveyRecallRecognitionData,
      error: fetchingSurveyRecallRecognitionDataError,
      data: surveyRecallRecognitionData
    },
    fetcher: surveyRecallRecognitionDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: {
      loading: fetchingSurveyIntentionData,
      error: fetchingSurveyIntentionDataError,
      data: surveyIntentionData
    },
    fetcher: surveyIntentionDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { loading: fetchingSurveyCompletes, error: fetchingSurveyCompletesError, data: surveyCompletes },
    fetcher: surveyCompletesFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: {
      loading: fetchingSurveyRespondentsGenderData,
      error: fetchingSurveyRespondentsGenderDataError,
      data: surveyRespondentsGenderData
    },
    fetcher: surveyRespondentsGenderDataFetcher
  } = useAxios({
    initialValue: []
  });

  const {
    state: { loading: fetchingSurveyAgeData, error: fetchingSurveyAgeDataError, data: surveyAgeData },
    fetcher: surveyAgeDataFetcher
  } = useAxios({
    initialValue: {}
  });

  const {
    state: { data: surveyPdfData },
    fetcher: surveyPdfFetcher
  } = useAxios();

  const {
    state: {
      loading: fetchingFlightUpliftData,
      error: fetchingFlightUpliftDataError,
      data: flightUpliftData
    },
    fetcher: flightUpliftDataFetcher
  } = useAxios();

  const fetchSurveyPreviewLinks = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.preview-links',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyPreviewLinksFetcher({
      url: apiRoute,
      responseAdapter: ({ survey_preview_links }) => survey_preview_links
    });
  };

  const fetchSurveyRecallRecognitionData = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.recall-recognition',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyRecallRecognitionDataFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const fetchSurveyIntentionData = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.intention',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyIntentionDataFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const fetchSurveyCompletes = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.completes',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyCompletesFetcher({
      url: apiRoute,
      responseAdapter: ({ survey_completes }) => survey_completes
    });
  };

  const fetchSurveyRespondentsGenderData = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.gender',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyRespondentsGenderDataFetcher({
      url: apiRoute,
      responseAdapter: ({ surveys_gender }) => surveys_gender
    });
  };

  const fetchSurveyAgeData = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.fligts.survey.age',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyAgeDataFetcher({
      url: apiRoute,
      responseAdapter: ({ surveys_age }) => surveys_age
    });
  };

  const fetchSurveyPdfData = ({ campaignId, flightId }) => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.survey.export.pdf',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return surveyPdfFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  const fetchFlightUpliftData = () => {
    const apiRoute = getApiRoute({
      name: 'api.campaigns.flights.uplift-data',
      params: {
        id: campaignId,
        flight_id: flightId
      }
    });

    return flightUpliftDataFetcher({
      url: apiRoute,
      responseAdapter: ({ data }) => data
    });
  };

  return {
    fetchSurveyPreviewLinks,
    fetchingSurveyPreviewLinks,
    fetchingSurveyPreviewLinksError,
    surveyPreviewLinks,
    fetchSurveyRecallRecognitionData,
    fetchingSurveyRecallRecognitionData,
    fetchingSurveyRecallRecognitionDataError,
    surveyRecallRecognitionData,
    fetchSurveyIntentionData,
    fetchingSurveyIntentionData,
    fetchingSurveyIntentionDataError,
    surveyIntentionData,
    fetchSurveyCompletes,
    fetchingSurveyCompletes,
    fetchingSurveyCompletesError,
    surveyCompletes,
    fetchSurveyRespondentsGenderData,
    fetchingSurveyRespondentsGenderData,
    fetchingSurveyRespondentsGenderDataError,
    surveyRespondentsGenderData,
    fetchSurveyAgeData,
    fetchingSurveyAgeData,
    fetchingSurveyAgeDataError,
    surveyAgeData,
    fetchSurveyPdfData,
    surveyPdfData,
    fetchFlightUpliftData,
    fetchingFlightUpliftData,
    fetchingFlightUpliftDataError,
    flightUpliftData
  };
};
