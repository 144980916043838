import axios from 'axios';
import { getApiRoute } from 'routeconfig';
import { Mutation, Action } from 'store/_global/types';
import { Message } from 'reppublika_components';
import { getTokenFromCookies } from 'helper';
import { useUserModules, useAuth } from 'composables';

const { fetchUserModules } = useUserModules();
const { fetchTokens, checkTokenValid } = useAuth();

const fetchUserCountries = () => {
  const fetchUserCountriesApiRoute = getApiRoute('api.user.countries');

  return axios.get(fetchUserCountriesApiRoute).then(({ data }) => {
    const { countries: userCountries, country_code: defaultCountryCode } = data;

    return { userCountries, defaultCountryCode };
  });
};

export default {
  [Action.CHECK_MAINTENANCE_STATUS]({ state, commit }) {
    if (!state.currentUser) return;

    return fetchUserModules().then(modules => {
      commit(`${Mutation.SET_MAINTENANCE}`, [...modules.filter(elem => !!elem.maintenance)]);
    });
  },
  [Action.FETCH_USER_INFO]({ commit }) {
    return axios.get(getApiRoute('api.user.info')).then(({ data }) => {
      const { user } = data?.data || {};

      if (!!user) {
        commit(Mutation.UPDATE_USER, user);
      }

      return user;
    });
  },
  [Action.FETCH_HELPERS]({ commit }) {
    const url = getApiRoute({ name: 'api.helpers.all' });
    // Request fresh texts :)
    axios
      .get(url)
      .then(res => {
        commit(`${Mutation.STORE_TOOLTIPS}`, res.data);
      })
      .catch(error => {
        console.log(
          `%cError: ${error.response.config.method} request failed.\nResponse: HTTP Status ${error.response.data.code}: ${error.response.data.error}`,
          'background: #222; color:#ff6363; font-size:18px '
        );
      });
  },
  [Action.CHECK_TOKENS]({ commit, dispatch }, { token, mustRefresh = false }) {
    return checkTokenValid(token).then(token_is_valid => {
      const saveToken = (newToken = token) => {
        commit(Mutation.SAVE_TOKEN, newToken);
        commit(Mutation.UPDATE_TOKEN);
      };

      if (token_is_valid && !mustRefresh) {
        saveToken();

        return true;
      }

      return fetchTokens().then(async data => {
        saveToken(data);

        fetchUserModules().then(modules => {
          commit(Mutation.UPDATE_APPS, modules);
        });

        await dispatch(Action.FETCH_USER_INFO);

        return;
      });
    });
  },
  async [Action.CHECK_USER_SESSION](
    { commit, dispatch, state },
    { to, needsPriviledge, checkRights, alreadyOnLoginPage = false }
  ) {
    const token = getTokenFromCookies();

    if (!token) {
      if (alreadyOnLoginPage) return;

      return dispatch(Action.GO_TO_LOGIN, { to });
    }

    try {
      await dispatch(Action.CHECK_TOKENS, { token });
    } catch (error) {
      return dispatch(Action.GO_TO_LOGIN, { to });
    }

    // fetch user session & countries
    if ((state.currentUser && !state.selectedCountryCode) || !state.currentUser) {
      return fetchUserCountries().then(({ userCountries, defaultCountryCode }) => {
        const { country_code: countryCodeFromParams } = to.params;
        const countryFromLocalStorage = localStorage.getItem('selectedCountryCode');
        const fetchUserSession = () => {
          if (state.currentUser) return;

          return dispatch(Action.FETCH_USER_SESSION, { needsPriviledge, to, checkRights });
        };

        if (!!countryCodeFromParams) {
          const isCountryCodeFromParamsValid = userCountries.some(
            country => country?.code === countryCodeFromParams
          );

          if (!isCountryCodeFromParamsValid) {
            return {
              name: 'not-found'
            };
          }

          commit(Mutation.SET_SELECTED_COUNTRY_CODE, countryCodeFromParams);

          return fetchUserSession();
        }

        if (!!countryFromLocalStorage) {
          const isCountryFromLocalStorageValid = userCountries.some(
            country => country?.code === countryFromLocalStorage
          );

          if (isCountryFromLocalStorageValid) {
            commit(Mutation.SET_SELECTED_COUNTRY_CODE, countryFromLocalStorage);

            return fetchUserSession();
          }
        }

        commit(Mutation.SET_SELECTED_COUNTRY_CODE, defaultCountryCode);

        return fetchUserSession();
      });
    }

    if (needsPriviledge && !state.currentUser.admin) {
      return dispatch(Action.GO_TO_HOME);
    }

    if (checkRights) {
      return dispatch(Action.CHECK_RIGHTS, { to });
    }

    return dispatch(Action.HANDLE_NEXT, { to });
  },
  [Action.GO_TO_LOGIN]({ commit }, { to }) {
    commit(`${Mutation.UPDATE_USER}`, false);

    if (to.path.includes('login')) return;

    const { name, query, params } = to;
    commit(Mutation.SET_REDIRECT_PATH, {
      name,
      query,
      params
    });

    return { name: 'login' };
  },
  [Action.GO_TO_HOME]() {
    return { name: 'startpage' };
  },
  [Action.FETCH_USER_SESSION]({ dispatch }, { needsPriviledge, to, checkRights }) {
    return dispatch(Action.FETCH_USER_INFO)
      .then(user => {
        if (!user) {
          return dispatch(Action.GO_TO_LOGIN, { to });
        }

        if ((needsPriviledge && !user.admin) || to.path.indexOf('login') !== -1) {
          return dispatch(Action.GO_TO_HOME);
        }

        if (checkRights) {
          return dispatch(Action.CHECK_RIGHTS, { to });
        }

        return dispatch(Action.HANDLE_NEXT, { to });
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || error?.message;

        console.error(`FETCH_USER_SESSION error: ${errorMessage}`);

        Message.error('Something went wrong - an error occurred while ensuring user session.');

        return dispatch(Action.GO_TO_LOGIN, { to });
      });
  },
  [Action.CHECK_RIGHTS]({ dispatch }, { to }) {
    const isBrandCheck = to.meta?.app === 'brand_check';
    const isBrandCheckFlight = to.params?.flight_id;
    let query = {};

    if (to.meta && (to.meta.app === 'campaign' || isBrandCheck)) {
      if (isBrandCheck) {
        query = { brand_check: 1 };
      }

      if (isBrandCheckFlight) {
        query = {
          ...query,
          flight_id: isBrandCheckFlight
        };
      }

      return dispatch(Action.HAS_ACCESS_TO_PAGE, {
        to,
        apiRoute: {
          name: 'api.campaigns.authorize',
          params: {
            id: to.params.id
          }
        },
        query
      });
    }
    if (to.meta && to.meta.app === 'ratings') {
      return dispatch(Action.HAS_ACCESS_TO_PAGE, {
        to,
        apiRoute: {
          name: 'api.ratings.details.authorize',
          params: { id: to.params.id, type: to.params.type }
        },
        query
      });
    }

    if (to.meta && to.meta.app === 'publisher') {
      return dispatch(Action.HAS_ACCESS_TO_PAGE, {
        to,
        apiRoute: { name: 'api.publishers.authorize', params: { id: to.params.id } },
        query
      });
    }

    return { name: 'no-permission' };
  },
  [Action.HAS_ACCESS_TO_PAGE]({ dispatch }, { to, apiRoute, query }) {
    const url = getApiRoute(apiRoute);

    return axios
      .get(url, {
        ...(Object.keys(query).length ? { params: { ...query } } : {})
      })
      .then(({ data }) => {
        if (data.authorized) {
          return dispatch(Action.HANDLE_NEXT, { to });
        }

        return { name: 'no-permission' };
      })
      .catch(() => ({
        name: 'no-permission'
      }));
  },
  [Action.HANDLE_NEXT]({ getters }, { to }) {
    let hasAccess = false;

    if (!to.meta?.module && !to.meta?.modules) {
      hasAccess = true;
    }

    if (to.meta?.module && getters.currentUserModules[to.meta.module]) {
      hasAccess = true;
    }

    if (Array.isArray(to.meta?.modules)) {
      hasAccess = to.meta.modules.some(module => getters.currentUserModules[module]);
    }

    if (hasAccess) return;

    return { name: 'no-permission' };
  }
};
